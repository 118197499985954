export default function(instance) {
	return {
		getEntry(id) {
			return instance.get(`motivator/entry/${id}`).then(response => response.data);
		},
		update(payload) {
			return instance.post(`motivator/update`, payload).then(response => response.data);
		},
		insert(payload) {
			return instance.post(`motivator/create`, payload).then(response => response.data);
		},
		remove(id) {
			return instance.delete(`motivator/delete`, { data: { id } }).then(response => response.data);
		}
	}
}