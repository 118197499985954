<template v-if="id">
    <label v-if="title" :for="id" class="form-label">
        {{ title }}
    </label>

    <select
        :id="id"
        :disabled="disabled"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        class="form-select"
        :class="$attrs.class"
    >
        <option v-if="placeholder" disabled value="">{{ placeholder }}</option>
        <option v-if="nullTitle" :selected="options.includes(modelValue) === false" value="">{{ nullTitle }}</option>
        <option v-for="option in options" :value="option.value">
            {{ option.title }}
        </option>
    </select>

    <div v-if="!!this.$slots.hint" class="remarks-wrapper">
        <slot name="hint"></slot>
    </div>
</template>
  
<script>
import { customAlphabet } from 'nanoid';

const nanoid = customAlphabet('1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-', 10);

export default {
    props: {
        modelValue: {
            type: String
        },
        title: {
            type: String
        },
        nullTitle: {
            type: String,
            default: null
        },
        options: {
            type: Array,
            default: () => null,
            validator(value) {
                // The only valid arrays are those which only have 'title' and 'value' keys in their entries.
                let valid = true;

                for(const v of value) {
                    valid = valid && Object.keys(v).every(elem => ["title", "value"].includes(elem));
                }

                return valid;
            }
        },
        placeholder: {
            type: String,
            default: "Выберите значение"
        },
        disabled: {
            type: Boolean,
            default: null
        }
    },

    data () {
        return {
            id: false
        }
    },

    mounted() {
        this.id = 'sl' + nanoid();
    },

    methods: {
        
    },

    components: {
        
    },

    emits: ['update:modelValue']
}
</script>

<style></style>