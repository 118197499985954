export default function(instance) {
    return {
			getEntry(id) {
				return instance.get(`recipe/entry/${id}`).then(response => response.data);
			},
			getCategory(id) {
				return instance.get(`recipe/category/${id}`).then(response => response.data);
			},
			all() {
				return instance.get('recipe/all').then(response => response.data);
			},
			allCategories() {
				return instance.get('recipe/categories/all').then(response => response.data);
			},
			uploadImage(payload) {
				return instance.post(`recipe/upload-image`, payload).then(response => response.data);
			},
			update(payload) {
				return instance.post(`recipe/update`, payload).then(response => response.data);
			},
			insert(payload) {
				return instance.post(`recipe/create`, payload).then(response => response.data);
			},
			remove(id) {
				return instance.delete(`recipe/delete`, { data: { id } }).then(response => response.data);
			},
			insertCategory(payload) {
				return instance.post(`recipe/category/create`, payload).then(response => response.data);
			},
			updateCategory(payload) {
				return instance.post(`recipe/category/update`, payload).then(response => response.data);
			},
			removeCategory(id) {
				return instance.delete(`recipe/category/delete`, { data: { id } }).then(response => response.data);
			}
    }
}