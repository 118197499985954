<template v-if="id">
	<label v-if="title" :for="id" class="form-label">
			{{ title }}
	</label>

	<simple-typeahead
		:id="id"
		ref="inputRef"
		:class="['form-control']"
		:placeholder="placeholder"
		:items="options"
		:minInputLength="1"
		:itemProjection="({ title }) => title"
		@selectItem="selectItem"
		@onInput="onInput"
		@onBlur="onBlur"
	>
	</simple-typeahead>

	<div v-if="!!this.$slots.hint" class="remarks-wrapper">
			<slot name="hint"></slot>
	</div>
</template>

<script>
import SimpleTypeahead from 'vue3-simple-typeahead';
import 'vue3-simple-typeahead/dist/vue3-simple-typeahead.css';
import { customAlphabet } from 'nanoid';

const nanoid = customAlphabet('1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-', 10);

export default {
	props: {
			modelValue: {
					type: String
			},
			title: {
					type: String
			},
			nullTitle: {
					type: String,
					default: null
			},
			options: {
					type: Array,
					default: () => null,
					validator(value) {
							// The only valid arrays are those which only have 'title' and 'value' keys in their entries.
							let valid = true;

							for(const v of value) {
									valid = valid && Object.keys(v).every(elem => ["title", "value"].includes(elem));
							}

							return valid;
					}
			},
			placeholder: {
					type: String,
					default: "Выберите значение"
			},
			disabled: {
					type: Boolean,
					default: null
			}
	},

	data () {
			return {
					id: false,
					currentOption: null
			}
	},

	watch: {
    options(newOptions, oldOptions) {
			this.verifySelection(newOptions, true);
    }
  },

	mounted() {
			this.id = 'sl' + nanoid();
			this.currentOption = this.modelValue;
			this.verifySelection(this.options, true);
	},

	methods: {
		verifySelection(newOptions, force = false) {
			if(newOptions?.length) {
				this.$refs.inputRef.selectItem(newOptions.find(({ value }) => value == this.modelValue) ?? null);
			}
		},
		selectItem(item) {
			this.currentOption = item.id;
			this.$emit('update:modelValue', item.id);
		},
		onInput(event) {
			this.$emit('update:modelValue', null);
		},
		onBlur(event) {

		},
	},

	components: {
		SimpleTypeahead
	},

	emits: ['update:modelValue']
}
</script>

<style></style>