<template>
    <div class="panel-section-wrapper">
        <div class="panel-section-title">
            <h2>{{ title }}</h2>
        </div>
        <div class="panel-section-container">
            <div v-if="!!this.$slots['prepended-hint']" class="remarks-wrapper">
                <slot name="prepended-hint"></slot>
            </div>
            <slot></slot>
            <div v-if="!!this.$slots['appended-hint']" class="remarks-wrapper">
                <slot name="appended-hint"></slot>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    props: {
        title: {
            type: String
        }
    },

    data () {
      return {

      }
    },

    mounted() {

    },

    methods: {

    },

    components: {

    }
  }
</script>

<style>
</style>