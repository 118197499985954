<template>
	<div>
		<h1>
			{{ additionMode ? 'Добавить' : 'Изменить' }}
			статью
		</h1>

		<form @submit.prevent="save">
			<div class="container-fluid">
				<div class="row mb-3 g-0">
					<div class="col-12 col-lg-9 col-xl-7">
						<div class="container-fluid">
							<div class="row gy-3">
								<div class="col-12">
									<Input
										title="Название статьи"
										placeholder="Моя новая статья"
										v-model="entry.title"
									>
										<template #hint>
											<p>
												Качественный заголовок привлекает посетителя к прочтению текста и выражает суть материала коротко и понятно. Чем длиннее заголовок и чем больше в нём несущественной информации, тем меньше шансов, что посетитель прочтёт его полностью и продолжит чтение самого текста. Поэтому лучшая длина заголовка — до 60, в крайнем случае до 100 символов.
											</p>

											<p>
												Заголовки страниц фигурируют в поисковой выдаче поисковых систем, а по исследованиям известно, что пользователи читают там только первые слова заголовков.
											</p>
										</template>
									</Input>
								</div>

								<div class="col-12">
									<Select
										v-model="entry.category"
										title="Категория статьи"
										nullTitle="Отсутствует"
										:options="categories"
									>
										<template #hint>
											Статья может быть сохранена и без категории, но в таком случае не будет отображаться на сайте, поскольку название категории положено в состав ссылки на статью.
										</template>
									</Select>
								</div>

								<div class="col-12">
									<Input
										type="textarea"
										rows="4"
										title="Краткое описание статьи"
										v-model="entry.summary"
									>
										<template #hint>
											Краткое описание статьи отображается в большей части мест сайта, содержащих упоминания о нём, а также в результатах поиска Яндекс, Google и других поисковых машин в блоке краткого описания. Должно содержать основную мысль статьи или интригующую фразу для формирования у посетителей представления о ней и желания прочесть больше.
										</template>
									</Input>
								</div>

								<div class="col-12">
									<PanelSection title="Обложка статьи">
										<ImageCropModal
											@doneCropping="receiveCrops"
										/>

										<div class="mt-4">
											<h4>Сохранённые изображения</h4>
											<div class="row justify-content-between">
												<div class="col-12 col-lg-3 text-start">
													<img id="cover16x9" style="width:192px; height:108px;" class="mw-100" :src="entry.covers['16x9']" />
												</div>

												<div class="col-12 col-lg-3 text-center">
													<img id="cover9x16" style="width:61px; height:108px;" class="mw-100" :src="entry.covers['9x16']" />
												</div>

												<div class="col-12 col-lg-3 text-center">
													<img id="cover5x4" style="width:135px; height:108px;" class="mw-100" :src="entry.covers['5x4']" />
												</div>

												<div class="col-12 col-lg-3 text-end">
													<img id="cover1x1" style="width:108px; height:108px;" class="mw-100" :src="entry.covers['1x1']" />
												</div>
											</div>
										</div>
									</PanelSection>
								</div>

								<div class="col-12">
									<Input
											type="richtextarea"
											title="Полный текст статьи"
											v-model="entry.content"
									/>
								</div>

								<div class="col-12">
									<TagInput
										v-model="entry.tags"
									/>
								</div>

								<div class="col-12">
									<Checkbox
										title="Скрыть продукт на сайте"
										v-model="entry.hidden"
									/>                               
								</div>
							</div>
						</div>
					</div>

					<div class="col-0 col-lg-3 col-xl-5">
					
					</div>
				</div>

				<div class="row">
						<div class="col-6">
								<button type="submit" class="btn btn-save-item">
										{{ additionMode ? 'Добавить' : 'Изменить' }}
								</button>
								
								&nbsp;&nbsp;&nbsp;
		
								<ConfirmationModal
										v-if="!additionMode"
										btn-title="Удалить"
										btn-class="btn-delete-item"
										title="Подтверждение операции"
										@confirm="remove"
								>
										Вы действительно хотите удалить эту статью?
								</ConfirmationModal>
		
								&nbsp;&nbsp;&nbsp;
						</div>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
import Input from '../../generic/input/Input.vue';
import Select from '../../generic/input/Select.vue';
import Checkbox from '../../generic/input/Checkbox.vue';
import TagInput from '../../generic/input/TagInput.vue';
import ConfirmationModal from '../../generic/modal/ConfirmationModal.vue';
import ImageCropModal from '../../generic/modal/ImageCropModal.vue';
import PanelSection from '../../ui/PanelSection.vue';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

export default {
	data () {
		return {
			entry: {
					id: null,
					title: '',
					summary: '',
					category: null,
					content: '',
					tags: [],
					covers: [],
					hidden: false
			},
			categories: [],
			additionMode: true
		}
	},

	mounted() {
		this.initCategories();
		this.initEntry();
	},

	methods: {
		async initEntry() {
			const entryId    = this.$el.parentNode.dataset.id;

			if(entryId) {
				const entryData = await this.$api.articles.getEntry(entryId);

				this.entry   = entryData;

				this.additionMode = false;
			}
		},
		async initCategories() {
			const categoryList = await this.$api.articles.allCategories();

			this.categories = categoryList.map(({ title, id: value }) => ({ title, value }));
		},
		receiveCrops(crops) {
			this.entry.covers = crops;
		},
		add() {
			this.$api.articles.insert((({ id, title, summary, category, content, tags, hidden }) => ({ id, title, summary, category, content, tags, hidden }))(this.entry)).then(res => {
				toast.success("Запись продукта добавлена в базу. Сохранение данных...", {
					autoClose: 4000,
				});

				this.entry.id = res.id;

				this.update();
			});
		},
		update() {
			const uploadRequests = [];

			for(const [key, value] of Object.entries(this.entry.covers)) {
				if(value && value.includes('base64')) {
					uploadRequests.push(this.$api.articles.uploadImage({
						type: `cover${key}`,
						image: value,
						product: this.entry.id
					}));
				}
			}

			Promise.allSettled(uploadRequests).then(resp => {
				// console.log("Изображения обложек загружены.");

				this.$api.articles.update((({ id, title, summary, category, content, tags, hidden }) => ({ id, title, summary, category, content, tags, hidden }))(this.entry)).then(res => {
					toast.success("Изменения сохранены!", {
						autoClose: 4000,
					});
				});
			});
		},
		save() {
			if(this.additionMode) {
				// console.log('добавлено');
				toast.info("Добавление начинается...", {
					autoClose: 2000,
				});
				this.add();
			} else {
				// console.log('сохранено');
				toast.info("Сохранение начинается...", {
					autoClose: 2000,
				});
				this.update();
			}
		},
		remove() {
			this.$api.articles.remove(this.entry.id).then(res => {
				window.location.href = '/panel-vue/articles';
			});
		}
	},

	components: {
		Input, TagInput, Select, Checkbox, ConfirmationModal, ImageCropModal, PanelSection
	}
}
</script>

<style>
</style>