<template v-if="id">
    <!-- Button trigger modal -->
    <!-- <button v-if="btnTitle" type="button" class="btn" :class="[btnClass]" data-bs-toggle="modal" :data-bs-target="selectorId">
        {{ btnTitle }}
    </button> -->

    <!-- Modal -->
    <div class="modal fade" ref="modal" :class="$attrs.class" :id="id" tabindex="-1" aria-hidden="true">
        <div 
            class="modal-dialog"
            :class="{
                'modal-dialog-centered': centered, 
                'modal-dialog-scrollable': scrollable,
                'modal-sm': size === 'sm',
                'modal-lg': size === 'lg',
                'modal-xl': size === 'xl'
            }">
            <div class="modal-content">
                <div v-if="showHeader" class="modal-header">
                    <h1 class="modal-title fs-5">{{ title }}</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Закрыть"></button>
                </div>
                <div class="modal-body">
                    <slot></slot>
                </div>
                <div v-if="showActions" class="modal-footer">
                    <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary">Save changes</button> -->

                    <slot name="actions"></slot>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import { customAlphabet } from 'nanoid';
import { Modal as BsModal } from 'bootstrap';

const nanoid = customAlphabet('1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-', 10);

export default {
    expose: [
        'show', 'hide', 'toggle'
    ],
    props: {
        // Bootstrap options
        centered: {
            type: Boolean,
            default: false
        },
        scrollable: {
            type: Boolean,
            default: false
        },
        size: {
            type: String,
            validator(value) {
                return ['sm', 'lg', 'xl'].includes(value);
            }
        },
        // Custom options
        btnTitle: {
            type: String,
            default: null
        },
        btnClass: {
            type: String,
            default: 'btn-link'
        },
        title: {
            type: String,
            default: 'Без названия'
        },
        showHeader: {
            type: Boolean,
            default: true
        },
        showActions: {
            type: Boolean,
            default: true
        }
    },

    data () {
        return {
            id: false,
            selectorId: false,
            opened: false,
            bsmodal: null
        }
    },

    mounted() {
        this.id = 'mdl' + nanoid();
        this.selectorId = `#${this.id}`;

        this.bsmodal = new BsModal(this.$refs.modal, { keyboard: false });

        this.$refs.modal.addEventListener('shown.bs.modal', this.onShow);
        this.$refs.modal.addEventListener('hidden.bs.modal', this.onHide);
    },

    destroyed() {
        this.$refs.modal.removeEventListener('shown.bs.modal', this.onShow);
        this.$refs.modal.removeEventListener('hidden.bs.modal', this.onHide);
    },

    methods: {
        show() {
            this.bsmodal.show();
        },
        hide() {
            this.bsmodal.hide();
        },
        toggle() {
            if(this.opened) {
                this.bsmodal.hide();
            } else {
                this.bsmodal.show();
            }
        },
        onShow() {
            this.opened = true;
            this.$emit('shown');
        },
        onHide() {
            this.opened = false;
            this.$emit('hidden');
        },
    },

    emits: ['shown', 'hidden']
}
</script>

<style></style>