export default function(instance) {
	return {
		getEntry(id) {
			return instance.get(`advice/entry/${id}`).then(response => response.data);
		},
		update(payload) {
			return instance.post(`advice/update`, payload).then(response => response.data);
		},
		insert(payload) {
			return instance.post(`advice/create`, payload).then(response => response.data);
		},
		remove(id) {
			return instance.delete(`advice/delete`, { data: { id } }).then(response => response.data);
		}
	}
}