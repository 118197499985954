export default function(instance) {
	return {
		all() {
			return instance.get('article/all').then(response => response.data);
		},
		getEntry(id) {
			return instance.get(`article/entry/${id}`).then(response => response.data);
		},
		getCategory(id) {
			return instance.get(`article/category/${id}`).then(response => response.data);
		},
		allCategories() {
				return instance.get('article/categories/all').then(response => response.data);
		},
		uploadImage(payload) {
			return instance.post(`article/upload-image`, payload).then(response => response.data);
		},
		update(payload) {
			return instance.post(`article/update`, payload).then(response => response.data);
		},
		insert(payload) {
			return instance.post(`article/create`, payload).then(response => response.data);
		},
		remove(id) {
			return instance.delete(`article/delete`, { data: { id } }).then(response => response.data);
		},
		insertCategory(payload) {
			return instance.post(`article/category/create`, payload).then(response => response.data);
		},
		updateCategory(payload) {
			return instance.post(`article/category/update`, payload).then(response => response.data);
		},
		removeCategory(id) {
			return instance.delete(`article/category/delete`, { data: { id } }).then(response => response.data);
		}
	}
}