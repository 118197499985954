<template>
	<div v-for="(step, index) in modelValue" :key="step.key" v-if="modelValue && modelValue.length" class="step-wrapper my-3">
		<div class="step-container d-flex justify-content-between gap-3">
				<div>
						<div class="step-dropzone-container">
								<div class="step-dropzone-wrapper">
										<div v-show="step.image" class="step-image">
											<img class="mw-100" :alt="`Изображение к шагу ${index+1}`" :src="getImageUrl(step.image)" />
										</div>
										<div class="step-dropzone-content">
												<div class="step-dropzone-icon">
													<i class="fa-solid fa-cloud-arrow-up"></i>
												</div>
												<div class="step-dropzone-text">
														<ImageCropModal
															btnTitle="Выбрать файл"
															btnClass="btn-step-file-choose"
															:resolutionsAllowed="['1x1']"
															@doneCropping="crops => attachImage(index, crops)"
														/>

														<!-- <input class="step-dropzone-default-btn" id="step-dropzone-default-btn-{{ $index }}" type="file" hidden>
														<button class="btn btn-step-file-choose" id="btn-step-file-choose-{{ $index }}" type="button">
																Выбрать файл
														</button> -->
												</div>
										</div>

										<button v-show="step.image" type="button" class="btn step-dropzone-cancel-btn" @click="detachImage(index)">
											<i class="fa-solid fa-xmark"></i>
										</button>

										<div class="step-dropzone-file-name">
												Загруженный файл
										</div>
								</div>
						</div>
				</div>
				<div class="w-100 flex-1">
					<Input
						type="textarea"
						rows="7"
						placeholder="Введите описание"
						v-model="step.value"
					/>
				</div>
				<div>
						<button type="button" class="btn btn-delete-item-link" @click="remove(index)">
								Удалить
						</button>
				</div>
		</div>
	</div>
	<div v-else>
		Шаги приготовления отсутствуют.
	</div>

	<div class="mt-4">
			<button @click="add" type="button" class="btn btn-append">
					Добавить шаг
			</button>
	</div>
</template>

<script>
import { customAlphabet } from 'nanoid';
import Input from '../../../generic/input/Input.vue';
import ImageCropModal from '../../../generic/modal/ImageCropModal.vue';

const nanoid = customAlphabet('1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-', 15);

export default {
	props: {
			modelValue: {
					type: Array,
					default: () => null
			},
			
	},

	data () {
			return {
					
			}
	},

	mounted() {

	},

	methods: {
			add() {
					this.$emit('update:modelValue', [...(this.modelValue || []), { key: nanoid(), value: '', image: null }]);
			},
			remove(index) {
					this.$emit('update:modelValue', this.modelValue.filter((value, arrIndex) => index !== arrIndex));
			},
			attachImage(index, crops) {
				const modifiedArr = this.modelValue.slice();
				modifiedArr[index].image = crops['1x1'];
				this.$emit('update:modelValue', modifiedArr);
			},
			detachImage(index) {
				const modifiedArr = this.modelValue.slice();
				modifiedArr[index].image = null;
				this.$emit('update:modelValue', modifiedArr);
			},
			getImageUrl(img) {
				return img ? ( img.includes('base64') ? img : this.$asset(img) ) : ''; 
			}
	},

	components: {
			Input, ImageCropModal
	},

	emits: ['update:modelValue']
}
</script>

<style></style>