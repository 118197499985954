import './bootstrap';

import '../sass/app.scss';

import * as bootstrap from 'bootstrap';
import 'cropperjs/dist/cropper.css';
import Cropper from 'cropperjs';

import { createApp } from 'vue';

import ProductForm from './components/panel/Products/Form.vue';
import ProductCategoryForm from './components/panel/Products/Categories/Form.vue';

import ArticleForm from './components/panel/Articles/Form.vue';
import ArticleCategoryForm from './components/panel/Articles/Categories/Form.vue';

import RecipeForm from './components/panel/Recipes/Form.vue';
import RecipeCategoryForm from './components/panel/Recipes/Categories/Form.vue';

import PageForm from './components/panel/Pages/Form.vue';

import AdviceForm from './components/panel/Advices/Form.vue';
import MotivatorForm from './components/panel/Motivators/Form.vue';
import TriviaForm from './components/panel/Trivia/Form.vue';

import SettingsForm from './components/panel/Settings/Form.vue';

import api from './plugins/api';
import asset from './plugins/asset';

createApp(PageForm).use(api).use(asset).mount('.page-form');

createApp(ProductForm).use(api).use(asset).mount('.product-form');
createApp(RecipeForm).use(api).use(asset).mount('.recipe-form');
createApp(ArticleForm).use(api).use(asset).mount('.article-form');

createApp(ProductCategoryForm).use(api).use(asset).mount('.product-category-form');
createApp(RecipeCategoryForm).use(api).use(asset).mount('.recipe-category-form');
createApp(ArticleCategoryForm).use(api).use(asset).mount('.article-category-form');

createApp(AdviceForm).use(api).use(asset).mount('.advice-form');
createApp(MotivatorForm).use(api).use(asset).mount('.motivator-form');
createApp(TriviaForm).use(api).use(asset).mount('.trivia-form');

createApp(SettingsForm).use(api).use(asset).mount('.settings-form');

import '../sass/ckeditor.scss';

window.bootstrap = bootstrap;
window.cropper   = Cropper;