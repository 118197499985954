<template v-if="id">
    <label v-if="title" :for="id" class="form-label">
        {{ title }}
    </label>

    <textarea
        v-if="type === 'textarea'"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        :id="id"
        :disabled="disabled"
        :placeholder="placeholder"
        :rows="rows"
        class="form-control"
        :class="$attrs.class"
    ></textarea>
    <div v-else-if="type === 'richtextarea'" class="bg-white">
        <ckeditor
            @ready="onEditorReady"
            :editor="editor"
            :model-value="modelValue"
            @input="onEditorInput"
            :config="editorConfig"
        />
    </div>
		<input
			v-else-if="type === 'number'"
			:value="modelValue"
			@input="$emit('update:modelValue', $event.target.value)"
			:id="id"
			type="text"
			:disabled="disabled"
			:placeholder="placeholder"
			class="form-control"
			:class="$attrs.class"
		/>
    <input
        v-else
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        :id="id"
        :type="type"
        :disabled="disabled"
        :placeholder="placeholder"
        class="form-control"
        :class="$attrs.class"
    />

    <div v-if="!!this.$slots.hint" class="remarks-wrapper">
        <slot name="hint"></slot>
    </div>
</template>
  
<script>
import { customAlphabet } from 'nanoid';
import CKEditor from '@ckeditor/ckeditor5-vue';

import ClassicEditorBase from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials';
import AutoformatPlugin from '@ckeditor/ckeditor5-autoformat/src/autoformat';
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
import BlockQuotePlugin from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import HeadingPlugin from '@ckeditor/ckeditor5-heading/src/heading';
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link';
import ListPlugin from '@ckeditor/ckeditor5-list/src/list';
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import '@ckeditor/ckeditor5-build-classic/build/translations/ru.js';

class ClassicEditor extends ClassicEditorBase {}

ClassicEditor.builtinPlugins = [
    EssentialsPlugin,
    AutoformatPlugin,
    BoldPlugin,
    ItalicPlugin,
    BlockQuotePlugin,
    HeadingPlugin,
    LinkPlugin,
    ListPlugin,
    ParagraphPlugin
];

const nanoid = customAlphabet('1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-', 10);

export default {
    props: {
        modelValue: {
            type: String
        },
        title: {
            type: String
        },
        rows: {
            type: Number,
            default: 4
        },
        type: {
            type: String,
            default: 'text',
            validator(value) {
                return ['text', 'number', 'email', 'password', 'tel', 'url', 'textarea', 'richtextarea'].includes(value);
            }
        },
        placeholder: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: null
        }
    },

    data () {
        return {
            id: false,
            editor: ClassicEditor,
            editorInstance: null,
            editorConfig: {
                toolbar: {
                    items: [
                        'heading',
                        '|',
                        'bold',
                        'italic',
                        'link',
                        'bulletedList',
                        'numberedList',
                        'blockQuote',
                        'undo',
                        'redo'
                    ]
                },
                language: 'ru'
            }
        }
    },

    mounted() {
        this.id = 'inp' + nanoid();
    },

    methods: {
        onEditorReady (editor) {
            this.editorInstance = editor;
        },
        onEditorInput () {
            if(this.editorInstance) {
                this.$emit('update:modelValue', this.editorInstance.getData())
            }
        }
    },

    components: {
        ckeditor: CKEditor.component
    },

    emits: ['update:modelValue']
}
</script>

<style></style>