export default function(instance) {
    return {
			allCategories() {
				return instance.get('categories/all').then(response => response.data);
			},
			getEntry(id) {
				return instance.get(`custom-page/entry/${id}`).then(response => response.data);
			},
			update(payload) {
				return instance.post(`custom-page/update`, payload).then(response => response.data);
			},
			insert(payload) {
				return instance.post(`custom-page/create`, payload).then(response => response.data);
			},
			remove(id) {
				return instance.delete(`custom-page/delete`, { data: { id } }).then(response => response.data);
			}
    }
}