<template>
  <div>
    <h1>
      {{ additionMode ? 'Добавить' : 'Изменить' }}
      мотиватор
    </h1>

    <form @submit.prevent="save">
      <div class="container-fluid">
        <div class="row mb-3 g-0">
          <div class="col-12 col-lg-9 col-xl-7">
            <div class="container-fluid">
              <div class="row gy-3">
                <div class="col-12">
                  <Input
                    type="textarea"
                    rows="4"
										title="Текст мотиватора"
                    placeholder="Самое сложное - сделать два первых шага."
                    v-model="entry.content"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="col-0 col-lg-3 col-xl-5">

          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <button type="submit" class="btn btn-save-item">
              {{ additionMode ? 'Добавить' : 'Изменить' }}
            </button>
            
            &nbsp;&nbsp;&nbsp;

            <ConfirmationModal
              v-if="!additionMode"
              btn-title="Удалить"
              btn-class="btn-delete-item"
              title="Тестовое модальное окно на Vue"
              @confirm="remove"
            >
              Вы действительно хотите удалить этот мотиватор?
            </ConfirmationModal>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
  import Modal from '../../generic/modal/Modal.vue';
  import ConfirmationModal from '../../generic/modal/ConfirmationModal.vue';
  import Input from '../../generic/input/Input.vue';
	import { toast } from 'vue3-toastify';
	import 'vue3-toastify/dist/index.css';

  export default {
    data () {
      return {
        entry: {
          id: null,
          content: ''
        },
        additionMode: true
      }
    },

    mounted() {
			this.initEntry();
    },

    methods: {
			async initEntry() {
				const entryId    = this.$el.parentNode.dataset.id;

				if(entryId) {
					const entryData = await this.$api.motivators.getEntry(entryId);

					this.entry   = entryData;

					this.additionMode = false;
				}
			},
      add() {
				this.$api.motivators.insert((({ id, content }) => ({ id, content }))(this.entry)).then(res => {
					toast.success("Запись мотиватора добавлена в базу. Сохранение данных...", {
						autoClose: 4000,
					});

					this.entry.id = res.id;

					this.update();
				});
			},
			update() {
				this.$api.motivators.update((({ id, content }) => ({ id, content }))(this.entry)).then(res => {
					toast.success("Изменения сохранены!", {
						autoClose: 4000,
					});
				});
			},
      save() {
        if(this.additionMode) {
					toast.info("Добавление начинается...", {
						autoClose: 2000,
					});
					this.add();
        } else {
					toast.info("Сохранение начинается...", {
						autoClose: 2000,
					});
					this.update();
        }
      },
      remove() {
				this.$api.motivators.remove(this.entry.id).then(res => {
					window.location.href = '/panel-vue/motivators';
				});
      }
    },

    components: {
      Modal, ConfirmationModal, Input
    }
  }
</script>

<style></style>