<template>
  <div>
    <h1>
      {{ additionMode ? 'Добавить' : 'Изменить' }}
      категорию продуктов
    </h1>

    <form @submit.prevent="save">
      <div class="container-fluid">
        <div class="row mb-3 g-0">
          <div class="col-12 col-lg-9 col-xl-7">
            <div class="container-fluid">
              <div class="row gy-3">
                <div class="col-12 col-lg-9 col-xl-7">
                  <Input
                    title="Название категории"
                    placeholder="Новая категория"
                    v-model="entry.title"
                  />
                </div>

                <div class="col-12 col-lg-3 col-xl-5">
                  <Select
                    v-model="entry.parent"
                    title="Родительская категория"
                    nullTitle="Отсутствует"
                    :options="categories"
                  />
                </div>

                <div class="col-12">
                  <Input
                    type="textarea"
                    rows="4"
                    placeholder="Здесь размещается описание категории"
                    v-model="entry.summary"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="col-0 col-lg-3 col-xl-5">

          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <button type="submit" class="btn btn-save-item">
              {{ additionMode ? 'Добавить' : 'Изменить' }}
            </button>
            
            &nbsp;&nbsp;&nbsp;

            <ConfirmationModal
              v-if="!additionMode"
              btn-title="Удалить"
              btn-class="btn-delete-item"
              title="Тестовое модальное окно на Vue"
              @confirm="remove"
            >
              Вы действительно хотите удалить эту категорию?
            </ConfirmationModal>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
  import Modal from '../../../generic/modal/Modal.vue';
  import ConfirmationModal from '../../../generic/modal/ConfirmationModal.vue';
  import Input from '../../../generic/input/Input.vue';
  import Select from '../../../generic/input/Select.vue';
	import { toast } from 'vue3-toastify';
	import 'vue3-toastify/dist/index.css';

  export default {
    data () {
      return {
        entry: {
          id: null,
          title: '',
          parent: null,
          summary: ''
        },
        categories: [],
        additionMode: true
      }
    },

    mounted() {
			this.initCategories();
			this.initEntry();
    },

    methods: {
      async initCategories() {
        const categoryList = await this.$api.products.allCategories();

        this.categories = categoryList.map(({ title, id: value }) => ({ title, value }));
      },
			async initEntry() {
				const entryId    = this.$el.parentNode.dataset.id;

				if(entryId) {
					const entryData = await this.$api.products.getCategory(entryId);

					this.entry   = entryData;
					this.categories = this.categories.filter(({ value }) => value !== this.entry.id);

					this.additionMode = false;
				}
			},
      add() {
				this.$api.products.insertCategory((({ id, title, parent, summary }) => ({ id, title, parent, summary }))(this.entry)).then(res => {
					toast.success("Запись категории добавлена в базу. Сохранение данных...", {
						autoClose: 4000,
					});

					this.entry.id = res.id;

					this.update();
				});
			},
			update() {
				this.$api.products.updateCategory((({ id, title, parent, summary }) => ({ id, title, parent, summary }))(this.entry)).then(res => {
					toast.success("Изменения сохранены!", {
						autoClose: 4000,
					});
				});
			},
      save() {
        if(this.additionMode) {
					toast.info("Добавление начинается...", {
						autoClose: 2000,
					});
					this.add();
        } else {
					toast.info("Сохранение начинается...", {
						autoClose: 2000,
					});
					this.update();
        }
      },
      remove() {
				this.$api.products.removeCategory(this.entry.id).then(res => {
					window.location.href = '/panel-vue/products/categories';
				});
      }
    },

    components: {
      Modal, ConfirmationModal, Input, Select
    }
  }
</script>

<style></style>