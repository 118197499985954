<template>
	<div>
		<h1>
			{{ additionMode ? 'Добавить' : 'Изменить' }}
			страницу
		</h1>

		<form @submit.prevent="save">
			<div class="container-fluid">
					<div class="row mb-3 g-0">
							<div class="col-12 col-lg-9 col-xl-7">
									<div class="container-fluid">
											<div class="row gy-3">
													<div class="col-12">
															<Input
																	title="Название страницы"
																	placeholder="Новая страница"
																	v-model="entry.title"
															/>
													</div>

													<div class="col-12">
															<Input
																	type="richtextarea"
																	title="Содержимое страницы"
																	v-model="entry.content"
															/>
													</div>

													<div class="col-12">
															<Checkbox
																	title="Скрыть страницу на сайте"
																	v-model="entry.hidden"
															/>                               
													</div>
											</div>
									</div>
							</div>

							<div class="col-0 col-lg-3 col-xl-5">
							
							</div>
					</div>

					<div class="row">
							<div class="col-6">
									<button type="submit" class="btn btn-save-item">
											{{ additionMode ? 'Добавить' : 'Изменить' }}
									</button>
									
									&nbsp;&nbsp;&nbsp;
			
									<ConfirmationModal
											v-if="!additionMode"
											btn-title="Удалить"
											btn-class="btn-delete-item"
											title="Подтверждение операции"
											@confirm="remove"
									>
											Вы действительно хотите удалить эту страницу?
									</ConfirmationModal>
			
									&nbsp;&nbsp;&nbsp;
							</div>
					</div>
			</div>
	</form>
	</div>
</template>

<script>
  import Input from '../../generic/input/Input.vue';
  import Select from '../../generic/input/Select.vue';
  import Checkbox from '../../generic/input/Checkbox.vue';
  import ConfirmationModal from '../../generic/modal/ConfirmationModal.vue';
	import { toast } from 'vue3-toastify';
	import 'vue3-toastify/dist/index.css';

  export default {
    data () {
      return {
        entry: {
          id: null,
          title: '',
          content: '',
          hidden: null
        },
        additionMode: true
      }
    },

    mounted() {
			this.initEntry();

      // const entryData = this.$el.parentNode.dataset.entry;

      // // console.log(entryData);

      // if(entryData) {
      //   const entry = JSON.parse(entryData);

      //   entry.hidden = !!entry.hidden;

      //   this.entry = entry;
      //   this.additionMode = false;
      // }
    },

    methods: {
			async initEntry() {
				const entryId    = this.$el.parentNode.dataset.id;

				if(entryId) {
					const entryData = await this.$api.page.getEntry(entryId);

					this.entry   = entryData;

					this.additionMode = false;
				}
			},
			add() {
				this.$api.page.insert((({ id, title, content, hidden }) => ({ id, title, content, hidden: !!hidden }))(this.entry)).then(res => {
					toast.success("Запись страницы добавлена в базу. Сохранение данных...", {
						autoClose: 4000,
					});

					this.entry.id = res.id;

					this.update();
				}).catch(function (error) {
					toast.error("Произошла ошибка.", {
						autoClose: 4000,
					});
				});
			},
			update() {
				this.$api.page.update((({ id, title, content, hidden }) => ({ id, title, content, hidden }))(this.entry)).then(res => {
					toast.success("Изменения сохранены!", {
						autoClose: 4000,
					});
				}).catch(function (error) {
					toast.error("Произошла ошибка.", {
						autoClose: 4000,
					});
				});
			},
      save() {
        if(this.additionMode) {
          // console.log('добавлено');
					toast.info("Добавление начинается...", {
						autoClose: 2000,
					});
					this.add();
        } else {
          // console.log('сохранено');
					toast.info("Сохранение начинается...", {
						autoClose: 2000,
					});
					this.update();
        }
      },
      remove() {
				this.$api.page.remove(this.entry.id).then(res => {
					window.location.href = '/panel-vue/pages';
				});
			}
    },

    components: {
      Input, Select, Checkbox, ConfirmationModal
    }
  }
</script>

<style>
</style>