<template>
	<div class="product-list">
		<div class="products">
			<div v-for="(product, index) in modelValue" :key="product.key" v-if="modelValue && modelValue.length" class="ingredient-block d-flex flex-column flex-lg-row justify-content-between align-items-stretch gap-4 py-2">
				<div class="ingredient-title w-100 flex-1">
					<Typeahead
						v-model="product.id"
						title="Продукт"
						nullTitle="Не выбран"
						:options="productTitles"
					/>
				</div>
				<div class="ingredient-amount d-flex justify-content-between gap-2">
					<div class="w-100 flex-1">
						<Input
							type="number"
							title="Количество"
							v-model="product.amount"
						/>
					</div>
					<div class="align-self-end pb-2">
						<Select
							v-model="product.measure"
							title="Мера"
							:nullTitle="measureUnit(product.id)"
							:options="relativeMeasures(product.id)"
						/>
					</div>
				</div>
				<div class="ingredient-tools d-flex justify-content-center align-items-end">
					<button @click="remove(index)" type="button" class="btn btn-delete-item-link">
						Удалить
					</button>
				</div>
			</div>
			<div v-else>
				В данный раздел не добавлено ни одного продукта.
			</div>
		</div>

		<div class="mt-4">
			<button @click="add" type="button" class="btn btn-append btn-append-product">
				Добавить продукт
			</button>
		</div>
	</div>
</template>

<script>
import { isProxy, toRaw } from 'vue';
import Input from '../../../generic/input/Input.vue';
import { customAlphabet } from 'nanoid';
import Select from '../../../generic/input/Select.vue';
import Typeahead from '../../../generic/input/Typeahead.vue';

const nanoid = customAlphabet('1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-', 15);

export default {
	props: {
			modelValue: {
				type: Array,
				default: () => null
			},
			productTitles: {
				type: Array,
				default: () => null
			}
	},

	data () {
			return {

			}
	},

	mounted() {

	},

	methods: {
		measureUnit(id) {
			const titles = isProxy(this.productTitles) ? toRaw(this.productTitles) : this.productTitles;

			return titles.find(({ id: itemId }) => id == itemId)?.measureUnit || '-';
		},
		add() {
				this.$emit('update:modelValue', [...(this.modelValue || []), { key: nanoid(), id: null, amount: null, measure: null }]);
		},
		remove(index) {
				this.$emit('update:modelValue', this.modelValue.filter((value, arrIndex) => index !== arrIndex));
		},
		relativeMeasures(id) {
			const titles   = isProxy(this.productTitles) ? toRaw(this.productTitles) : this.productTitles,
						newTitle = titles.find(({ id: itemId }) => id == itemId);

			const result = [];

			if(newTitle?.pieces) {
				result.push({ title: 'шт', value: 1 });
			}

			if(newTitle?.packets) {
				result.push({ title: 'пак', value: 2 });
			}

			if(newTitle?.drips) {
				result.push({ title: 'кап', value: 3 });
			}

			return result;
		}
	},

	components: {
			Input, Select, Typeahead
	},

	emits: ['update:modelValue']
}
</script>

<style></style>