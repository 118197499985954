<template>
	<div class="product-sets-container mb-4">
		<div v-for="(set, index) in modelValue" :key="set.key" v-if="modelValue && modelValue.length" class="ingredient-set">
			<div class="ingredient-set-title d-flex justify-content-between align-items-center gap-3">
				<div class="flex-1 w-100">
					<Input
						v-model="set.title"
						placeholder="Название раздела"
					/>
				</div>
				<div>
					<button @click="remove(index)" type="button" class="btn btn-danger"> 
						<i class="fa-solid fa-trash-can"></i>
					</button>
				</div>
			</div>
			<div class="ingredient-set-content">
				<ProductList
					:productTitles="productTitles"
					v-model="set.products"
				/>
			</div>
		</div>
		<div v-else>
			В данный рецепт не добавлено ни одного набора.
		</div>

		<div class="mt-4">
			<button @click="add" type="button" class="btn btn-append btn-append-product-set">
				Добавить набор продуктов
			</button>
		</div>
	</div>
</template>

<script>
import Input from '../../../generic/input/Input.vue';
import { customAlphabet } from 'nanoid';
import Select from '../../../generic/input/Select.vue';
import ProductList from './ProductList.vue';

const nanoid = customAlphabet('1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-', 15);

export default {
	props: {
			modelValue: {
					type: Array,
					default: () => null
			},
			productTitles: {
				type: Array,
				default: () => null
			}
	},

	data () {
			return {
					
			}
	},

	mounted() {

	},

	methods: {
			add() {
					this.$emit('update:modelValue', [...(this.modelValue || []), { key: nanoid(), title: '', products: [] }]);
			},
			remove(index) {
					this.$emit('update:modelValue', this.modelValue.filter((value, arrIndex) => index !== arrIndex));
			}
	},

	components: {
			Input, Select, ProductList
	},

	emits: ['update:modelValue']
}
</script>

<style></style>