<template>
    <div v-for="(note, index) in modelValue" :key="note.key" v-if="modelValue && modelValue.length" class="note-wrapper my-3">
        <div class="note-container d-flex justify-content-between gap-3">
            <div class="w-100 flex-1">
                <Input
                    type="textarea"
                    rows="4"
                    v-model="note.value"
                />
            </div>
            <div>
                <button @click="remove(index)" type="button" class="btn btn-delete-item-link">
                    Удалить
                </button>
            </div>
        </div>
    </div>
    <div v-else>
        Примечания к рецепту отсутствуют.
    </div>

    <div class="mt-4">
        <button @click="add" type="button" class="btn btn-append">
            Добавить примечание
        </button>
    </div>
</template>
  
<script>
import Input from '../../../generic/input/Input.vue';
import { customAlphabet } from 'nanoid';

const nanoid = customAlphabet('1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-', 15);

export default {
    props: {
        modelValue: {
            type: Array,
            default: () => null
        },
        
    },

    data () {
        return {
            
        }
    },

    mounted() {

    },

    methods: {
        add() {
            this.$emit('update:modelValue', [...(this.modelValue || []), { key: nanoid(), value: '' }]);
        },
        remove(index) {
            this.$emit('update:modelValue', this.modelValue.filter((value, arrIndex) => index !== arrIndex));
        }
    },

    components: {
        Input
    },

    emits: ['update:modelValue']
}
</script>

<style></style>