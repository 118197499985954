<template>
	<div>
		<h1>
			Настройки сайта
		</h1>

		<form @submit.prevent="save">
			<div class="container-fluid">
				<div class="row mb-3 g-0">
					<div class="col-12 col-lg-9 col-xl-7">
						<div class="container-fluid">
							<div class="row gy-3">
								<div class="col-12">
									<Input
										title="Название сайта"
										placeholder="Блог Лики Вуле"
										v-model="settings.site_title"
									/>
								</div>

								<div class="col-12">
									<Input
										title="Слоган сайта"
										placeholder="Рецепты для жизни"
										v-model="settings.site_motto"
									/>
								</div>

								<div class="col-12">
									<Input
										type="textarea"
										title="Описание сайта"
										v-model="settings.site_description"
									/>
								</div>

								<div class="col-12">
									<Input
										title="Название блока об авторе"
										placeholder="Об авторе"
										v-model="settings.about_block_title"
									/>
								</div>

								<div class="col-12">
									<Input
										type="richtextarea"
										title="Содержимое блока об авторе"
										v-model="settings.about_block_content"
									/>
								</div>

								<div class="col-12">
									<Input
										title="Название блока в центре внимания"
										placeholder="В центре внимания"
										v-model="settings.spotlight_block_title"
									/>
								</div>

								<div class="col-12">
									<Typeahead
										v-model="settings.spotlight_block_entry"
										title="Рецепт в центре внимания"
										nullTitle="Не выбран"
										:options="recipes"
									/>				
								</div>

								<div class="col-12">
									<h3>Авторские рекомендации</h3>

									<div>
										<Typeahead
											v-model="settings.author_choice_1"
											title="Рекомендация #1"
											nullTitle="Не выбран"
											:options="recipes"
										/>
									</div>

									<div>
										<Typeahead
											v-model="settings.author_choice_2"
											title="Рекомендация #2"
											nullTitle="Не выбран"
											:options="recipes"
										/>
									</div>

									<div>
										<Typeahead
											v-model="settings.author_choice_3"
											title="Рекомендация #3"
											nullTitle="Не выбран"
											:options="recipes"
										/>
									</div>

									<div>
										<Typeahead
											v-model="settings.author_choice_4"
											title="Рекомендация #4"
											nullTitle="Не выбран"
											:options="recipes"
										/>
									</div>

									<div>
										<Typeahead
											v-model="settings.author_choice_5"
											title="Рекомендация #5"
											nullTitle="Не выбран"
											:options="recipes"
										/>
									</div>
								</div>

								<div class="col-12">
									<Input
										type="textarea"
										title="Описание раздела рецептов"
										v-model="settings.recipes_description"
									/>
								</div>

								<div class="col-12">
									<Input
										type="textarea"
										title="Описание раздела продуктов"
										v-model="settings.products_description"
									/>
								</div>

								<div class="col-12">
									<Input
										type="textarea"
										title="Описание раздела статей"
										v-model="settings.articles_description"
									/>
								</div>

								<div class="col-12">
									<Input
										type="richtextarea"
										title="Объявление на сайте"
										v-model="settings.announcement_content"
									/>
								</div>

								<div class="col-12">
									<Checkbox
										title="Показывать объявление"
										v-model="settings.announcement_shown"
									/>
								</div>
							</div>
						</div>
					</div>

					<div class="col-0 col-lg-3 col-xl-5">
					
					</div>
				</div>

				<div class="row">
					<div class="col-6">
						<button type="submit" class="btn btn-save-item">
							Сохранить изменения
						</button>
					</div>
				</div>
			</div>
	</form>
	</div>
</template>

<script>
  import Input from '../../generic/input/Input.vue';
  import Checkbox from '../../generic/input/Checkbox.vue';
	import Typeahead from '../../generic/input/Typeahead.vue';
	import { toast } from 'vue3-toastify';
	import 'vue3-toastify/dist/index.css';

  export default {
    data () {
      return {
        settings: {
					site_title: 			   		'', // Блог Лики Вуле
					site_motto: 			   		'', // Рецепты для жизни
					site_description:    		'', // Здесь вы найдёте много интересных кулинарных рецептов с пошаговой фотосъёмкой, видеоуроки, полезные советы и статьи для всех ценителей кулинарного искусства.
					about_block_title:   		'', // Об авторе
					about_block_content: 		'', // Дорогие друзья и посетители блога! <...>
					spotlight_block_title:	'', // В центре внимания
					spotlight_block_entry:  null, // { type: 'recipe', id: 123 }
					author_choice_1:				null,
					author_choice_2:				null,
					author_choice_3:				null,
					author_choice_4:				null,
					author_choice_5:				null,
					recipes_description:    '', // Пошаговые фоторецепты полезных и сытных блюд. Благодаря подробным инструкциям, лёгкости изложения, а также фотографиям, сопровождающим каждый шаг, их сможет приготовить даже начинающий кулинар. 
					products_description:   '', // В этом разделе вы узнаете больше о продуктах из размещённых на сайте рецептов. На странице каждого продукта размещена таблица мер и весов, а также список его полезных свойств.
					articles_description:   '', // Авторский взгляд на различные события в жизни человека. Большое внимание отводится вопросам влияния еды на здоровье, рекомендациям по уходу за лицом и телом и многим другим интересным темам.
					announcement_content:   '', // <Текст объявления, если таковое помещается на главной>
					announcement_shown:			false, // Показывать ли текст объявления на сайте
        },
				recipes: [],
				products: [],
				articles: [],
				allTitles: []
      }
    },

    mounted() {
			this.initSettings();
			this.initProductTitles();
			this.initRecipeTitles();
			this.initArticleTitles();
    },

    methods: {
			async initSettings() {
				const settings = await this.$api.settings.get();

				this.settings = settings;
			},
			async initProductTitles() {
				const productList = await this.$api.products.all();

				this.products = productList.map(({ id, title, measureUnit, pieces, packets, drips }) => ({ id, title, measureUnit, value: id, pieces, packets, drips }));

				this.allTitles = this.allTitles.concat(this.products);
			},
			async initRecipeTitles() {
				const recipeList = await this.$api.recipes.all();

				this.recipes = recipeList.map(({ id, title }) => ({ id, title, value: id }));

				this.allTitles = this.allTitles.concat(this.recipes);
			},
			async initArticleTitles() {
				const articleList = await this.$api.articles.all();

				this.articles = articleList.map(({ id, title }) => ({ id, title, value: id }));

				this.allTitles = this.allTitles.concat(this.articles);
			},
			save() {
				toast.info("Сохранение началось...", {
					autoClose: 2000,
				});

				this.$api.settings.update((({ site_title, site_motto, site_description, about_block_title, about_block_content, spotlight_block_title, spotlight_block_entry, author_choice_1, author_choice_2, author_choice_3, author_choice_4, author_choice_5, recipes_description, products_description, articles_description, announcement_content, announcement_shown }) => ({ site_title, site_motto, site_description, about_block_title, about_block_content, spotlight_block_title, spotlight_block_entry, author_choice_1, author_choice_2, author_choice_3, author_choice_4, author_choice_5, recipes_description, products_description, articles_description, announcement_content, announcement_shown }))(this.settings)).then(res => {
					toast.success("Изменения сохранены!", {
						autoClose: 4000,
					});
				}).catch(function (error) {
					toast.error("Произошла ошибка.", {
						autoClose: 4000,
					});
				});
			},
      remove() {
				this.$api.page.remove(this.entry.id).then(res => {
					window.location.href = '/panel-vue/pages';
				});
			}
    },

    components: {
      Input, Checkbox, Typeahead
    }
  }
</script>

<style>
</style>