<template v-if="id">
	<label v-if="title" :for="id" class="form-label">
		{{ title }}
	</label>

	<div class="tag-box">
		<transition-group name="tags" tag="ul">
			<li
				v-for="(tag, index) in modelValue"
				class="tag"
				:key="tag.key"
			>
				{{ tag.value }}
				<i class="fa-solid fa-xmark" @click="removeTag(index)"></i>
			</li>
		</transition-group>

		<input
			:id="id"
			class="tagInput"
			type="text"
			v-model="inputTag"
			@keydown.enter.prevent="addTag"
			@keydown.,.prevent="addTag"
			@keydown.backspace="popTag"
		/>
	</div>
</template>
  
<script>
import { customAlphabet } from 'nanoid';

const nanoid = customAlphabet('1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-', 21);

export default {
    props: {
        modelValue: {
            type: Array,
						default: []
        },
        title: {
            type: String,
            default: "Ключевые слова"
        },
        disabled: {
            type: Boolean,
            default: null
        }
    },

    data () {
        return {
        		id: false,
						inputTag: ''
        }
    },

    mounted() {
        this.id = 'ti' + nanoid();
    },

    methods: {
			removeTag (index) {
				this.$emit('update:modelValue', this.modelValue.filter((value, arrIndex) => index !== arrIndex));
			},
			addTag () {
				if(this.inputTag) {
					if(!this.modelValue.includes(this.inputTag))
						this.$emit('update:modelValue', [...(this.modelValue || []), { key: nanoid(), value: this.inputTag }]);
					this.inputTag = '';
				}
			},
			popTag () {
				if(this.modelValue.length > 0 && this.inputTag.length === 0)
					this.removeTag(this.modelValue.length - 1);
			}
    },

    components: {
        
    },

    emits: ['update:modelValue']
}
</script>

<style>
.tags-move, /* apply transition to moving elements */
.tags-enter-active,
.tags-leave-active {
  transition: all 0.5s ease;
}

.tags-enter-from,
.tags-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.tags-leave-active {
  position: absolute;
}
</style>