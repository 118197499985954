import instance from "./instance";

import productModule from "./models/product";
import pageModule from "./models/page";
import recipeModule from "./models/recipe";
import articleModule from "./models/article";
import triviaModule from "./models/trivia";
import adviceModule from "./models/advice";
import motivatorModule from "./models/motivator";
import settingsModule from "./models/settings";

export default {
    products: productModule(instance),
    page: pageModule(instance),
    recipes: recipeModule(instance),
		articles: articleModule(instance),
		trivia: triviaModule(instance),
		advices: adviceModule(instance),
		motivators: motivatorModule(instance),
		settings: settingsModule(instance),
		getErrorMessage: (err) => {
			let result = "Неизвестная ошибка. Возможно, отсутствует связь с интернетом.";
		
			if (err.response) {
				switch(err.response.status) {
					case 400:
						result = `Ошибка 400: Запрос отклонён, поскольку он некорректен. Возможно, необходимо устранить неисправность в коде страницы.`;
						break;
					case 401:
						result = `Ошибка 401: Запрос отклонён из-за отказа в доступе. Возможно, истекла сессия и необходимо обновить страницу для повторного входа.`;
						break;
					case 402:
						result = `Ошибка 402: Запрос отклонён, поскольку для этого требуется оплата. Если ошибка не может быть связана с оплатой, необходимо устранить неисправность в коде страницы.`;
						break;
					case 403:
						result = `Ошибка 403: Запрос отклонён из-за недостатка прав на совершение запроса. Если ошибка возникает при наличии таких прав, необходимо устранить неисправность в коде страницы.`;
						break;
					case 404:
						result = `Ошибка 404: Запрос отклонён из-за отсутствия обработчика. Необходимо добавить обработчик в API.`;
						break;
					case 405:
						result = `Ошибка 405: Запрос отклонён, поскольку присланный метод обработки запрещён сервером. Возможно, необходимо устранить неисправность в коде страницы.`;
						break;
					case 406:
						result = `Ошибка 406: Запрос отклонён, поскольку сервер не определил способа его обработки. Возможно, необходимо устранить неисправность в коде страницы.`;
						break;
					case 407:
						result = `Ошибка 407: Запрос отклонён, поскольку прислан не через прокси-сервер. Возможно, необходимо устранить неисправность в коде страницы.`;
						break;
					case 408:
						result = `Ошибка 408: Запрос отклонён, поскольку его обработка занимает слишком много времени. Попробуйте сократить присылаемые данные.`;
						break;
					case 409:
						result = `Ошибка 409: Запрос отклонён из-за конфликта на сервере. Повторите запрос позднее.`;
						break;
					case 410:
						result = `Ошибка 410: Запрос отклонён, поскольку содержимое больше не существует.`;
						break;
					case 411:
						result = `Ошибка 411: Запрос отклонён, поскольку для его обработки требуется передать заголовок "Content-Length". Обратитесь к разработчику.`;
						break;
					case 412:
						result = `Ошибка 412: Запрос отклонён, поскольку доступ к требуемым для его обработки файлам или папкам запрещён. Обратитесь к разработчику.`;
						break;
					case 413:
						result = `Ошибка 413: Запрос отклонён из-за слишком большого объёма. Попробуйте удалить некоторые файлы из запроса и попробуйте ещё раз.`;
						break;
					case 414:
						result = `Ошибка 414: Ссылка запроса слишком длинная. Сократите запрос и попробуйте ещё раз.`;
						break;
					case 415:
						result = `Ошибка 415: Формат файла в запросе не поддерживается.`;
						break;
					case 416:
						result = `Ошибка 416: Запрос отклонён, поскольку выпадает из диапазона, указанного в заголовке "Range".`;
						break;
					case 417:
						result = `Ошибка 417: Запрос отклонён, поскольку содержимое расходится с ожидаемым, указанным в заголовке "Expect".`;
						break;
					case 421:
						result = `Ошибка 421: Похоже, что запрос пришёл на сервер по ошибке. Обратитесь к разработчику.`;
						break;
					case 422:
						result = `Ошибка 422: Содержимое запроса отклонено сервером. Проверьте правильность заполнения формы и попробуйте снова.`;
						break;
					case 423:
						result = `Ошибка 423: Запрос отклонён, поскольку обработчик защищён. Повторите запрос позднее.`;
						break;
					case 429:
						result = `Ошибка 429: Запрос отклонён, поскольку превышено ограничение запросов к серверу. Повторите запрос через несколько минут.`;
						break;
					case 500:
						result = `Ошибка 500: Запрос отклонён, поскольку сервер столкнулся с тем, что не может решить самостоятельно. Обратитесь к разработчику.`;
						break;
					case 502:
						result = `Ошибка 502: Запрос отклонён, поскольку получен неподдерживаемый ответ. Обратитесь к разработчику.`;
						break;
					case 503:
						result = `Ошибка 503: Запрос отклонён, поскольку сервер перегружен или недоступен. Убедитесь, что компьютер подключён к интернету и повторите запрос позднее.`;
						break;
					case 504:
						result = `Ошибка 504: Запрос отклонён, поскольку сервер не успел его обработать.`;
						break;
					default:
						result = "Возникла неизвестная ошибка.";
				}
			} else if (err.request) {
				result = `Ошибка: Запрос отправлен на сервер, но ответ от сервера не получен.`;
			}
		
			return result;
		}
}