<template v-if="id">
    <div>
        <a class="btn btn-collapse-toggle" data-bs-toggle="collapse" :href="selectorId" role="button" aria-expanded="false" :aria-controls="id">
            {{ title }}
        </a>

        <div class="collapse" :id="id">
            <div class="collapse-card card card-body">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
    import { customAlphabet } from 'nanoid';

    const nanoid = customAlphabet('1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-', 10);

  export default {
    props: {
        title: {
            type: String
        }
    },

    data () {
      return {
        id: false,
        selectorId: false
      }
    },

    mounted() {
        this.id = 'clpsbl' + nanoid();
        this.selectorId = `#${this.id}`;
    },

    methods: {

    },

    components: {

    }
  }
</script>

<style>
</style>