<template>
	<div class="product-list">
		<div class="products">
			<div v-for="(recipe, index) in modelValue" :key="recipe.key" v-if="modelValue && modelValue.length" class="ingredient-block d-flex flex-column flex-lg-row justify-content-between align-items-stretch gap-4 py-2">
				<div class="ingredient-title w-100 flex-1">
					<Typeahead
						v-model="recipe.id"
						title="Рецепт"
						nullTitle="Не выбран"
						:options="recipeTitles"
					/>
				</div>
				<div class="ingredient-amount d-flex justify-content-between gap-2">
					<div class="w-100 flex-1">
						<Input
							type="number"
							title="Пропорция"
							v-model="recipe.amount"
						/>
					</div>
				</div>
				<div class="ingredient-tools d-flex justify-content-center align-items-end">
					<button @click="remove(index)" type="button" class="btn btn-delete-item-link">
						Удалить
					</button>
				</div>
			</div>
			<div v-else>
				В данный рецепт не добавлено ни одного составного продукта.
			</div>
		</div>

		<div class="mt-4">
			<button @click="add" type="button" class="btn btn-append btn-append-product">
				Добавить составной продукт
			</button>
		</div>
	</div>
</template>

<script>
import Input from '../../../generic/input/Input.vue';
import { customAlphabet } from 'nanoid';
import Select from '../../../generic/input/Select.vue';
import Typeahead from '../../../generic/input/Typeahead.vue';

const nanoid = customAlphabet('1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-', 15);

export default {
	props: {
			modelValue: {
				type: Array,
				default: () => null
			},
			recipeTitles: {
				type: Array,
				default: () => null
			}
	},

	data () {
			return {

			}
	},

	mounted() {

	},

	methods: {
		add() {
				this.$emit('update:modelValue', [...(this.modelValue || []), { key: nanoid(), id: null, amount: 1 }]);
		},
		remove(index) {
				this.$emit('update:modelValue', this.modelValue.filter((value, arrIndex) => index !== arrIndex));
		},
	},

	components: {
			Input, Select, Typeahead
	},

	emits: ['update:modelValue']
}
</script>

<style></style>