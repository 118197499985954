<template v-if="id">
    <div class="form-check">
        <input
            :checked="modelValue"
            @change="$emit('update:modelValue', $event.target.checked)"
            class="form-check-input"
            type="checkbox"
            :id="id"
            :disabled="disabled"
        />

        <label v-if="title" :for="id" class="form-check-label">
            {{ title }}
        </label>
    </div>
</template>
  
<script>
import { customAlphabet } from 'nanoid';

const nanoid = customAlphabet('1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-', 10);

export default {
    props: {
        modelValue: {
            type: Boolean,
            default: false
        },
        title: {
            type: String
        },
        disabled: {
            type: Boolean,
            default: null
        }
    },

    data () {
        return {
            id: false
        }
    },

    mounted() {
        this.id = 'cb' + nanoid();
    },

    methods: {
        
    },

    components: {
        
    },

    emits: ['update:modelValue']
}
</script>

<style></style>