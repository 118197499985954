export default function(instance) {
    return {
			getEntry(id) {
				return instance.get(`product/entry/${id}`).then(response => response.data);
			},
			getCategory(id) {
				return instance.get(`product/category/${id}`).then(response => response.data);
			},
			all() {
				return instance.get('product/all').then(response => response.data);
			},
			allCategories() {
					return instance.get('product/categories/all').then(response => response.data);
			},
			measureUnits() {
					return instance.get('product/measure-units').then(response => response.data);
			},
			uploadImage(payload) {
				return instance.post(`product/upload-image`, payload).then(response => response.data);
			},
			update(payload) {
				return instance.post(`product/update`, payload).then(response => response.data);
			},
			insert(payload) {
				return instance.post(`product/create`, payload).then(response => response.data);
			},
			remove(id) {
				return instance.delete(`product/delete`, { data: { id } }).then(response => response.data);
			},
			insertCategory(payload) {
				return instance.post(`product/category/create`, payload).then(response => response.data);
			},
			updateCategory(payload) {
				return instance.post(`product/category/update`, payload).then(response => response.data);
			},
			removeCategory(id) {
				return instance.delete(`product/category/delete`, { data: { id } }).then(response => response.data);
			}
    }
}