<template>
    <div>
      <h1>
        {{ additionMode ? 'Добавить' : 'Изменить' }}
        продукт
      </h1>

      <form @submit.prevent="save">
				<div class="container-fluid">
					<div class="row mb-3 g-0">
						<div class="col-12 col-lg-9 col-xl-7">
							<div class="container-fluid">
								<div class="row gy-3">
									<div class="col-12">
										<Input
											title="Название продукта"
											placeholder="Новый продукт"
											v-model="entry.title"
										/>
									</div>

									<div class="col-12">
										<Select
											v-model="entry.category"
											title="Категория продукта"
											nullTitle="Отсутствует"
											:options="categories"
										>
											<template #hint>
												Продукт может быть сохранён и без категории, но в таком случае не будет отображаться на сайте, поскольку название категории положено в состав ссылки на продукт.
											</template>
										</Select>
									</div>

									<div class="col-12">
										<Input
											type="textarea"
											rows="4"
											title="Краткое описание продукта"
											v-model="entry.summary"
										>
											<template #hint>
												Краткое описание продукта отображается в большей части мест сайта, содержащих упоминания о нём, а также в результатах поиска Яндекс, Google и других поисковых машин в блоке краткого описания. Должно содержать информацию о вкусовых и других особенностях продукта для формирования у посетителей представления о нём. 
											</template>
										</Input>
									</div>

									<div class="col-12">
										<PanelSection title="Обложка продукта">
											<ImageCropModal
												@doneCropping="receiveCrops"
											/>

											<div class="mt-4">
												<h4>Сохранённые изображения</h4>
												<div class="row justify-content-between">
													<div class="col-12 col-lg-3 text-start">
														<img id="cover16x9" style="width:192px; height:108px;" class="mw-100" :src="entry.covers['16x9']" />
													</div>

													<div class="col-12 col-lg-3 text-center">
														<img id="cover9x16" style="width:61px; height:108px;" class="mw-100" :src="entry.covers['9x16']" />
													</div>

													<div class="col-12 col-lg-3 text-center">
														<img id="cover5x4" style="width:135px; height:108px;" class="mw-100" :src="entry.covers['5x4']" />
													</div>

													<div class="col-12 col-lg-3 text-end">
														<img id="cover1x1" style="width:108px; height:108px;" class="mw-100" :src="entry.covers['1x1']" />
													</div>
												</div>
											</div>
										</PanelSection>
									</div>

									<div class="col-12">
										<PanelSection title="Весы и меры">
											<template #prepended-hint>
												Основная единица измерения продукта &mdash; та, в которой задаётся его количество во всех связанных с ним рецептах. Например, зелень измеряется преимущественно <strong>в граммах</strong>, а мука &mdash; <strong>в килограммах</strong>.
											</template>

											<div class="row">
													<div class="col-12 pb-3">
														<Select
															v-model="entry.measureUnit"
															title="Основная единица измерения"
															:options="measureUnits"
														/>
													</div>

													<div class="col-12 col-lg-3">
														<Input
															type="number"
															title="В 1 ч.л."
															v-model="entry.tsp"
														/>
													</div>
							
													<div class="col-12 col-lg-3">
														<Input
															type="number"
															title="В 1 ст.л."
															v-model="entry.tbsp"
														/>
													</div>

													<div class="col-12 col-lg-3">
														<Input
															type="number"
															title="В 1 ст. 200 мл"
															v-model="entry.cup200"
														/>
													</div>
							
													<div class="col-12 col-lg-3">
														<Input
															type="number"
															title="В 1 ст. 250 мл"
															v-model="entry.cup250"
														/>
													</div>
											</div>

											<div class="row">
												<div class="col-12 pt-4">
													<h3>Относительные единицы измерения</h3>

													<div class="remarks-wrapper">
														<p>
															Возможно, продукт измеряется не только в основной единице измерения: порой более удобно указать продукт в рецепте, например, в пакетиках или штуках. Ниже можно соотнести их с основной единицей измерения.
														</p>
														<p>
															Если продукт <strong>не измеряется в какой-либо из этих мер</strong>, тогда поле можно оставить пустым либо указать в нём число 0.
														</p>
													</div>
												</div>
												
												<div class="col-12 col-lg-3">
														<Input
															type="number"
															title="В 1 шт."
															v-model="entry.pieces"
														/>
													</div>
							
													<div class="col-12 col-lg-3">
														<Input
															type="number"
															title="В 1 пак."
															v-model="entry.packets"
														/>
													</div>

													<div class="col-12 col-lg-3">
														<Input
															type="number"
															title="В 1 капле"
															v-model="entry.drips"
														/>
													</div>

													<div class="col-12 col-lg-3"></div>
											</div>
										</PanelSection>
									</div>

									<div class="col-12">
										<PanelSection title="Пищевая ценность">
											<template #prepended-hint>
												<p>
														Энергетическая ценность продукта измеряется в <strong>килокалориях</strong> (ккал). Одна килокалория равна 1000 калорий (кал). Калория определяется как количество энергии, необходимое для нагрева 1 г (или 1 мл) воды на 1°C. Для расчёта энергетической ценности (калорийности) блюд используются величины в 100 г продукта основных составляющих пищи: белков, жиров и углеводов.
												</p>
												<p>
														<em>Величины белков, жиров и углеводов указываются в расчёте на 1 грамм продукта.</em>
												</p>
											</template>

											<div class="row">
												<div class="col-12 col-lg-4">
													<Input
														type="number"
														title="Белки"
														v-model="entry.proteins"
													/>
												</div>
                
                        <div class="col-12 col-lg-4">
													<Input
														type="number"
														title="Жиры"
														v-model="entry.fats"
													/>
												</div>
                
                        <div class="col-12 col-lg-4">
													<Input
														type="number"
														title="Углеводы"
														v-model="entry.carbohydrates"
													/>
												</div>
											</div>
										</PanelSection>
									</div>

									<div class="col-12">
										<Input
												type="richtextarea"
												title="Полное описание"
												v-model="entry.content"
										/>
									</div>

									<div class="col-12">
										<TagInput
											v-model="entry.tags"
										/>
									</div>

									<div class="col-12">
										<Checkbox
											title="Скрыть продукт на сайте"
											v-model="entry.hidden"
										/>                               
									</div>
								</div>
							</div>
						</div>

						<div class="col-0 col-lg-3 col-xl-5">
						
						</div>
					</div>

					<div class="row">
							<div class="col-6">
									<button type="submit" class="btn btn-save-item">
											{{ additionMode ? 'Добавить' : 'Изменить' }}
									</button>
									
									&nbsp;&nbsp;&nbsp;
			
									<ConfirmationModal
											v-if="!additionMode"
											btn-title="Удалить"
											btn-class="btn-delete-item"
											title="Подтверждение операции"
											@confirm="remove"
									>
											Вы действительно хотите удалить этот продукт?
									</ConfirmationModal>
			
									&nbsp;&nbsp;&nbsp;
							</div>
					</div>
				</div>
			</form>
    </div>
</template>

<script>
	import Input from '../../generic/input/Input.vue';
	import Select from '../../generic/input/Select.vue';
	import Checkbox from '../../generic/input/Checkbox.vue';
	import TagInput from '../../generic/input/TagInput.vue';
	import ConfirmationModal from '../../generic/modal/ConfirmationModal.vue';
	import ImageCropModal from '../../generic/modal/ImageCropModal.vue';
	import PanelSection from '../../ui/PanelSection.vue';
	import { toast } from 'vue3-toastify';
	import 'vue3-toastify/dist/index.css';

  export default {
    data () {
      return {
        entry: {
          	id: null,
          	title: '',
						summary: '',
						category: null,
						measureUnit: null,
          	content: '',
						tags: [],
						covers: [],
						tsp: null,
						tbsp: null,
						cup200: null,
						cup250: null,
						pieces: null,
						packets: null,
						drips: null,
						proteins: null,
						fats: null,
						carbohydrates: null,
          	hidden: false
        },
				categories: [],
				measureUnits: [],
        additionMode: true
      }
    },

    mounted() {
      // const entryData = this.$el.parentNode.dataset.entry;

			this.initCategories();
			this.initMeasureUnits();
			this.initEntry();

      // console.log(entryData);

      // if(entryData) {
      //   const entry = JSON.parse(entryData);

      //   entry.hidden = !!entry.hidden;

      //   this.entry = entry;
      //   this.additionMode = false;
      // }
    },

    methods: {
			async initEntry() {
				const entryId    = this.$el.parentNode.dataset.id;

				if(entryId) {
					const entryData = await this.$api.products.getEntry(entryId);

					this.entry   = entryData;

					this.additionMode = false;
				}
			},
	  	async initCategories() {
        const categoryList = await this.$api.products.allCategories();

        this.categories = categoryList.map(({ title, id: value }) => ({ title, value }));
      },
			async initMeasureUnits() {
        const measureUnitsList = await this.$api.products.measureUnits();

        this.measureUnits = measureUnitsList.map(({ title, id: value }) => ({ title, value }));
      },
			receiveCrops(crops) {
				this.entry.covers = crops;
			},
			floatString(n) {
				return Number.isInteger(n) ? n.toFixed(1) : n.toString();
			},
			prepareFields() {
				this.entry.tsp = this.entry.tsp ? this.floatString(this.entry.tsp).split(',').join('.') : null;
				this.entry.tbsp = this.entry.tbsp ? this.floatString(this.entry.tbsp).split(',').join('.') : null;
				this.entry.cup200 = this.entry.cup200 ? this.floatString(this.entry.cup200).split(',').join('.') : null;
				this.entry.cup250 = this.entry.cup250 ? this.floatString(this.entry.cup250).split(',').join('.') : null;
				this.entry.pieces = this.entry.pieces ? this.floatString(this.entry.pieces).split(',').join('.') : null;
				this.entry.packets = this.entry.packets ? this.floatString(this.entry.packets).split(',').join('.') : null;
				this.entry.drips = this.entry.drips ? this.floatString(this.entry.drips).split(',').join('.') : null;

				this.entry.proteins = this.entry.proteins ? this.floatString(this.entry.proteins).split(',').join('.') : null;
				this.entry.fats = this.entry.fats ? this.floatString(this.entry.fats).split(',').join('.') : null;
				this.entry.carbohydrates = this.entry.carbohydrates ? this.floatString(this.entry.carbohydrates).split(',').join('.') : null;
			},
			add() {
				this.$api.products.insert((({ id, title, summary, category, measureUnit, content, hidden, tsp, tbsp, cup200, cup250, pieces, packets, drips, proteins, fats, carbohydrates, tags }) => ({ id, title, summary, category, measureUnit, content, hidden, tsp, tbsp, cup200, cup250, pieces, packets, drips, proteins, fats, carbohydrates, tags }))(this.entry)).then(res => {
					toast.success("Запись продукта добавлена в базу. Сохранение данных...", {
						autoClose: 4000,
					});

					this.entry.id = res.id;

					this.update();
				});
			},
			update() {
				const uploadRequests = [];

				for(const [key, value] of Object.entries(this.entry.covers)) {
					if(value && value.includes('base64')) {
						uploadRequests.push(this.$api.products.uploadImage({
							type: `cover${key}`,
							image: value,
							product: this.entry.id
						}));
					}
				}

				Promise.allSettled(uploadRequests).then(resp => {
					// console.log("Изображения обложек загружены.");

					this.$api.products.update((({ id, title, summary, category, measureUnit, content, hidden, tsp, tbsp, cup200, cup250, pieces, packets, drips, proteins, fats, carbohydrates, tags }) => ({ id, title, summary, category, measureUnit, content, hidden, tsp, tbsp, cup200, cup250, pieces, packets, drips, proteins, fats, carbohydrates, tags }))(this.entry)).then(res => {
						toast.success("Изменения сохранены!", {
							autoClose: 4000,
						});
					});
				});
			},
      save() {
				this.prepareFields();

        if(this.additionMode) {
          // console.log('добавлено');
					toast.info("Добавление начинается...", {
						autoClose: 2000,
					});
					this.add();
        } else {
          // console.log('сохранено');
					toast.info("Сохранение начинается...", {
						autoClose: 2000,
					});
					this.update();
        }
      },
      remove() {
				this.$api.products.remove(this.entry.id).then(res => {
					window.location.href = '/panel-vue/products';
				});
			}
    },

    components: {
      Input, TagInput, Select, Checkbox, ConfirmationModal, ImageCropModal, PanelSection
    }
  }
</script>

<style>
</style>