<template>
	<div>
		<h1>
			{{ additionMode ? 'Добавить' : 'Изменить' }}
			рецепт
		</h1>

		<form @submit.prevent="save">
			<div class="container-fluid">
				<div class="row mb-3 g-0">
					<div class="col-12 col-lg-9 col-xl-7">
						<div class="container-fluid">
							<div class="row gy-3">
								<div class="col-12">
									<Input
										title="Название рецепта"
										placeholder="Новый рецепт"
										v-model="entry.title"
									>
										<template #hint>
											Л. Шумахер в своей книге &laquo;The Art of Naming Dishes on Bills of Fare&raquo; приводит ряд рекомендаций для составления качественного названия рецепта.

											<Collapsible title="Подробнее...">
												<p>
														При составлении названия для рецепта учитываются два ключевых фактора: главные продукты и способ их приготовления. Вторичные дополнения, к примеру, география или имя автора, если присутствуют, то указываются после этого. Акцентировать внимание можно не столько на главных продуктах, сколько на категории, к которой они относятся. К примеру, главные продукты первого блюда могут быть мясом, овощами, фруктами и т.п., поскольку существуют мясные, овощные и фруктовые супы.
												</p>

												<p>
														В случае когда первое блюдо готовится преимущественно или строго с определённым видом мяса, овоща и т.п., необходимо указывать его тип: куриный суп, гороховый суп, томатный суп, вишнёвый суп и т.п. Если при этом блюдо готовится ещё и строго определённым образом, то его обязательно необходимо указывать: гороховый суп-пюре, протёртый томатный суп, говяжье консоме и т.п.
												</p>

												<p>
														Все продукты, заменимые без существенных нарушений во вкусе блюда, считаются вторичными и за редким исключением в названии не упоминаются, но если есть необходимость сделать акцент на определённом варианте блюда, это создаст о нём более чёткое впечатление: суп по-шуазельски, суп по-королевски, суп по-манхеттенски. Если вариаций несколько, они указываются вместе: гороховый суп-пюре по-шуазельски, протёртый томатный суп по-испански и т.п.
												</p>

												<p>
														Если в первом блюде содержится в основном единственный ингредиент, то допустимо указать его, если в этом есть особенность блюда: пельменный куриный суп, винный манный суп и т.п. Если опустить какую-нибудь особенность в названии, то не удастся отличить одно похожее блюдо от другого, хотя с точки зрения вкуса их разница существенна.
												</p>

												<p>
														Итак, общее правило: указываются основные продукты, способ их приготовления, затем иные детали. Есть несколько исключений в виде блюд с уже сформировавшимися стилями: фрикасе, тушёнка, рагу и т.п., но их основной ингредиент всё равно указывается. Если способ приготовления сложен и его нельзя выразить одним-двумя словами, то ему придумывается авторское название.
												</p>
											</Collapsible>
										</template>
									</Input>
								</div>

								<div class="col-12 col-lg-6">
									<Select
										v-model="entry.category"
										title="Категория рецепта"
										nullTitle="Отсутствует"
										:options="categories"
									>
										<template #hint>
											Рецепт может быть сохранён и без категории, но в таком случае не будет отображаться на сайте, поскольку название категории положено в состав ссылки на рецепт. 
										</template>
									</Select>
								</div>

								<div class="col-12 col-lg-6">
									<label class="form-label">
											Время приготовления
									</label>
									<div class="input-group">
										<Input
											type="number"
											placeholder="0"
											v-model="hours"
										/>
										<span class="input-group-text">ч</span>
										<Input
											type="number"
											placeholder="0"
											v-model="minutes"
										/>
										<span class="input-group-text">мин</span>
									</div>
								</div>

								<div class="col-12">
									<Input
										type="textarea"
										rows="4"
										title="Краткое описание рецепта"
										v-model="entry.summary"
									>
										<template #hint>
											Краткое описание рецепта отображается в большей части мест сайта, содержащих упоминания о нём, а также в результатах поиска Яндекс, Google и других поисковых машин в блоке краткого описания. Должно содержать информацию о вкусовых и других особенностях рецепта для формирования у посетителей представления о нём.
										</template>
									</Input>
								</div>

								<div class="col-12">
									<PanelSection title="Обложка рецепта">
										<ImageCropModal
											@doneCropping="receiveCrops"
										/>

										<div class="mt-4">
											<h4>Сохранённые изображения</h4>
											<div class="row justify-content-between">
												<div class="col-12 col-lg-3 text-start">
													<img id="cover16x9" style="width:192px; height:108px;" class="mw-100" :src="entry.covers['16x9']" />
												</div>

												<div class="col-12 col-lg-3 text-center">
													<img id="cover9x16" style="width:61px; height:108px;" class="mw-100" :src="entry.covers['9x16']" />
												</div>

												<div class="col-12 col-lg-3 text-center">
													<img id="cover5x4" style="width:135px; height:108px;" class="mw-100" :src="entry.covers['5x4']" />
												</div>

												<div class="col-12 col-lg-3 text-end">
													<img id="cover1x1" style="width:108px; height:108px;" class="mw-100" :src="entry.covers['1x1']" />
												</div>
											</div>
										</div>
									</PanelSection>
								</div>

								<div class="col-12">
									<PanelSection title="Видеорецепт">
										<template #prepended-hint>
											Поддерживается вставка любых ссылок на YouTube. Просмотры видео на сайте идут в зачёт статистики просмотров того же видео на YouTube. Видео отобразится на сайте только если ссылка на YouTube была распознана корректно.
										</template>

										<Input
											title="Ссылка на видеорецепт"
											placeholder="https://youtu.be/xxxxxxxx"
											v-model="entry.video"
										/>
									</PanelSection>
								</div>

								<div class="col-12">
									<PanelSection title="Список продуктов">
										<template #prepended-hint>
											Количество продукта указывается <strong>в его основных единицах измерения</strong>. Когда изменяется основная единица измерения продукта, эти величины пересчитываются по всем рецептам с округлением вверх до сотых.
										</template>

										<h3>Разделы продуктов</h3>

										<div class="remarks-wrapper">
											Если рецепт содержит сложные составляющие (опара, тесто, крем и т.п.), возможно создать <strong>раздел</strong> для перечисления входящих в них продуктов. В более простых рецептах, как правило, разделы не требуются.
										</div>

										<ProductSets
											:productTitles="products"
											v-model="entry.productSets"
										/>

										<h3>Прочие продукты</h3>

										<div class="remarks-wrapper">
											<strong>Важно:</strong> после сохранения рецепта все продукты каждого раздела, включая данный, упорядочиваются <strong>по убыванию их количества</strong>.
										</div>

										<ProductList
											:productTitles="products"
											v-model="entry.products"
										/>

										<div class="mt-4"></div>

										<h3>Составные продукты</h3>

										<div class="remarks-wrapper">
											Здесь приводятся рецепты для продуктов, требующих предварительного приготовления, вместе с пропорциями, в которых эти рецепты необходимо приготовить.
										</div>

										<RecipeList
											:recipeTitles="recipes"
											v-model="entry.recipes"
										/>
									</PanelSection>
								</div>

								<div class="col-12">
									<PanelSection title="Шаги приготовления">
										<template #prepended-hint>
											Рекомендуется загружать <strong>квадратные</strong> изображения для иллюстрирования шагов. В таком случае гарантируется корректность их отображения на странице рецепта.
										</template>

										<Steps
											v-model="entry.steps"
										/>
									</PanelSection>
								</div>

								<div class="col-12">
									<PanelSection title="Примечания">
										<Notes
											v-model="entry.notes"
										/>
									</PanelSection>
								</div>

								<div class="col-12">
									<TagInput
										v-model="entry.tags"
									/>
								</div>

								<div class="col-12">
									<Checkbox
										title="Скрыть рецепт на сайте"
										v-model="entry.hidden"
									/>                               
								</div>
							</div>
						</div>
					</div>

					<div class="col-0 col-lg-3 col-xl-5">
					
					</div>
				</div>

				<div class="row">
						<div class="col-6">
								<button type="submit" class="btn btn-save-item">
										{{ additionMode ? 'Добавить' : 'Изменить' }}
								</button>
								
								&nbsp;&nbsp;&nbsp;
		
								<ConfirmationModal
										v-if="!additionMode"
										btn-title="Удалить"
										btn-class="btn-delete-item"
										title="Подтверждение операции"
										@confirm="remove"
								>
										Вы действительно хотите удалить этот рецепт?
								</ConfirmationModal>
		
								&nbsp;&nbsp;&nbsp;
						</div>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
import Input from '../../generic/input/Input.vue';
import Select from '../../generic/input/Select.vue';
import Checkbox from '../../generic/input/Checkbox.vue';
import TagInput from '../../generic/input/TagInput.vue';
import ConfirmationModal from '../../generic/modal/ConfirmationModal.vue';
import ImageCropModal from '../../generic/modal/ImageCropModal.vue';
import PanelSection from '../../ui/PanelSection.vue';
import Collapsible from '../../ui/Collapsible.vue';
import Notes from './components/Notes.vue';
import Steps from './components/Steps.vue';
import ProductList from './components/ProductList.vue';
import ProductSets from './components/ProductSets.vue';
import RecipeList from './components/RecipeList.vue';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

export default {
	data () {
		return {
			entry: {
					id: null,
					title: '',
					summary: '',
					category: null,
					video: null,
					covers: [],
					tags: [],
					notes: [],
					steps: [],
					productSets: [],
					products: [],
					recipes: [],
					duration: null,
					hidden: false
			},
			hours: null,
			minutes: null,
			categories: [],
			products: [],
			recipes: [],
			additionMode: true
		}
	},

	mounted() {
		this.initCategories();
		this.initProductTitles();
		this.initEntry();
	},

	methods: {
		async initEntry() {
			const entryId    = this.$el.parentNode.dataset.id;

			if(entryId) {
				const entryData = await this.$api.recipes.getEntry(entryId);

				this.entry   = entryData;
				this.hours   = Math.floor(this.entry.duration / 60);
				this.minutes = this.entry.duration % 60;

				this.initRecipeTitles();

				this.additionMode = false;
			} else {
				this.initRecipeTitles();
			}
		},
		async initCategories() {
			const categoryList = await this.$api.recipes.allCategories();

			this.categories = categoryList.map(({ title, id: value }) => ({ title, value }));
		},
		async initProductTitles() {
			const productList = await this.$api.products.all();

			this.products = productList.map(({ id, title, measureUnit, pieces, packets, drips }) => ({ id, title, measureUnit, value: id, pieces, packets, drips }));
		},
		async initRecipeTitles() {
			const recipeList = await this.$api.recipes.all();

			this.recipes = recipeList.map(({ id, title }) => ({ id, title, value: id })).filter(({ value }) => value !== this.entry.id);
		},
		receiveCrops(crops) {
			this.entry.covers = crops;
		},
		add() {
			this.$api.recipes.insert((({ id, title, summary, category, video, tags, notes, steps, productSets, products, recipes, duration, hidden }) => ({ id, title, summary, category, video, tags, notes, steps, productSets, products, recipes, duration, hidden }))(this.entry)).then(res => {
				toast.success("Запись рецепта добавлена в базу. Сохранение данных...", {
					autoClose: 4000,
				});

				this.entry.id = res.id;

				this.update();
			}).catch(function (error) {
				toast.error("Произошла ошибка.", {
					autoClose: 4000,
				});
			});
		},
		update() {
			// Store text data, then upload all images sequentially.
			const stepUploadRequests = [];

			for(const [key, value] of Object.entries(this.entry.steps)) {
				if(value.image && value.image.includes('base64')) {
					stepUploadRequests.push(this.$api.recipes.uploadImage({
						type: `step`,
						image: value.image,
						recipe: this.entry.id
					}).then(response => {
						this.entry.steps[key].image = response;
					}));
				}
			}

			Promise.allSettled(stepUploadRequests).then(response => {
				// console.log("Изображения шагов загружены.");

				const uploadRequests = [];

				for(const [key, value] of Object.entries(this.entry.covers)) {
					if(value && value.includes('base64')) {
						uploadRequests.push(this.$api.recipes.uploadImage({
							type: `cover${key}`,
							image: value,
							recipe: this.entry.id
						}));
					}
				}

				Promise.allSettled(uploadRequests).then(resp => {
					// console.log("Изображения обложек загружены.");

					this.$api.recipes.update((({ id, title, summary, category, video, tags, notes, steps, productSets, products, recipes, duration, hidden }) => ({ id, title, summary, category, video, tags, notes, steps, productSets, products, recipes, duration, hidden }))(this.entry)).then(res => {
						toast.success("Изменения сохранены!", {
							autoClose: 4000,
						});
					}).catch(function (error) {
						toast.error("Произошла ошибка.", {
							autoClose: 4000,
						});
					});
				});
			});
		},
		save() {
			this.entry.duration = 60 * parseInt(this.hours, 10) + parseInt(this.minutes, 10) || 0;

			if(this.additionMode) {
				// console.log('добавлено');
				toast.info("Добавление начинается...", {
					autoClose: 2000,
				});
				this.add();
			} else {
				// console.log('сохранено');
				toast.info("Сохранение начинается...", {
					autoClose: 2000,
				});
				this.update();
			}
		},
		remove() {
			this.$api.recipes.remove(this.entry.id).then(res => {
				window.location.href = '/panel-vue/recipes';
			});
		}
	},

	components: {
		Input, TagInput, Select, Checkbox, ConfirmationModal, ImageCropModal, PanelSection, Collapsible, Notes, Steps, ProductList, ProductSets, RecipeList
	}
}
</script>

<style>
</style>