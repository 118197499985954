<template>
		<button v-if="btnTitle" type="button" class="btn" :class="[btnClass]" @click="$refs.modal.toggle()">
        {{ btnTitle }}
    </button>

    <Modal ref="modal" :title="title" centered>
        <slot></slot>

        <template #actions>
            <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Отмена</button>
            <button type="button" class="btn btn-primary" @click="$emit('confirm')" data-bs-dismiss="modal">Подтвердить</button>
        </template>
    </Modal>
</template>
  
<script>
import Modal from './Modal.vue';

export default {
    props: {
        title: {
            type: String,
            default: 'Без названия'
        },
        btnTitle: {
            type: String,
            default: 'Запрос'
        },
        btnClass: {
            type: String,
            default: 'btn-link'
        }
    },

    data () {
        return {

        }
    },

    mounted() {

    },

    methods: {
        
    },

    components: {
        Modal
    }, 

    emits: ['confirm']
}
</script>

<style></style>